import {React} from 'react'
import '../Home.css'
import logo from '../imgs/logo.png'

function Home() {

  const openKyc = () => {
      location.href = "/kyc";
  }

  return (
  <div className="home-page">
    <div className="card">
      <img src={logo} alt="Docsun" className="logo"/>
      <h2>Docsun AI</h2>
      <p className="title">Welcome to Docsun AI</p>
      <p>Instructions</p>
      <ol>
        <li>Please enter valid details of the patient in the Google form questionare.</li>
        <li>After the questionare has been filled please proceed to scanning , by clicking on the scanning button.</li>
        <li>For the scanning , please position patient face in front of camera and allow patient to adjust face in the green box and then proceed to click on Start , to initiate the scanning.</li>
        <li>Please note , the scanning results are only for reference basis to determine system bias and learning curve improvements , please do use it for medical purposes during this study.</li>
      </ol>
      <p><button className="btn" onClick={()=>openKyc()}>Continue</button></p>
    </div>
  </div>
  )

}

export default Home
