import '../Kyc.css';
import logo from '../imgs/logo.png'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function Kyc() {

  const navigate = useNavigate();

  // States for registration
  const [name, setName] = useState('');
  const [age, setAge] = useState('');

  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
    setSubmitted(false);
  };

  // Handling the age change
  const handleAge = (e) => {
    setAge(e.target.value);
    setSubmitted(false);
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === '' || age === '') {
      setError(true);
    } else {
      // setSubmitted(true);
      // setError(false);
      navigate("/questionare",{state :{ name : name, age:age }, replace:true});
    }
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
        <h1>User {name} successfully registered!!</h1>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? '' : 'none',
        }}>
        <p className="error">Please enter all the fields</p>
      </div>
    );
  };

  return (
    <div className="kyc-page">
      <form className="card">
      <img src={logo} alt="Docsun" className="logo"/>
      <h2>Docsun AI</h2>
      <p className="title">Please enter your information</p>
      {/* Calling to the methods */}
      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>
        {/* Labels and inputs for form data */}
        <div className="container">
        <label className="label">Name</label>
        <input type="text" onChange={handleName} className="form-control" value={name} />
        <label className="label">Age</label>
        <input type="number" onChange={handleAge} className="form-control" value={age} />
        </div>
        <button onClick={handleSubmit} className="btn" type="submit">Continue</button>
      </form>
    </div>
  );
}
