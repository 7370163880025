import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Scan from './components/Scan';
import Questionare from './components/Questionare';
import Home from './components/Home';
import Kyc from './components/Kyc';

function App() {
  return (
    <div>
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/scan" element={<Scan />} />
      <Route path="/questionare" element={<Questionare />} />
      <Route path="/kyc" element={<Kyc />} />
      </Routes>
      </Router>
    </div>
  );
}
export default App;
