import {React} from 'react'
import '../Scan.css'
import logo from '../imgs/logo.png'
import { useLocation, useNavigate } from 'react-router-dom';

function Scan() {

// const navigate = useNavigate();
//
// const { state } = useLocation();
// //let videoFileName = "Scanned-video";

// if(state === null ){
//   location.href = "/kyc";
// }

// const { name } = state || {};
// const { age } = state || {};

let name = localStorage.getItem('DocsunScannerName');
let age = localStorage.getItem('DocsunScannerAge');

let videoFileName = name+'-'+age;
//}

let stream = null;
let media_recorder = null;
let blobs_recorded = [];

const startRecording = () => {

    navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        })
        .then(stm => {
          stream = stm;

          // set MIME type of recording as video/webm
          media_recorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
          // event : new recorded video blob available
          //blobs_recorded.push(stream);

          media_recorder.ondataavailable = function(event) {
                blobs_recorded.push(event.data);
            };

          // start recording with each recorded blob having 1 second video
          media_recorder.start(1000);

        setTimeout(()=>{
            (()=>{
                    //stopRecording();
                    media_recorder.stop();
                    let video_local = URL.createObjectURL(new Blob(blobs_recorded, { type: 'video/webm' }));
                    var link = document.createElement("a"); // Or maybe get it from the current document
                      link.href = video_local;
                      link.download = videoFileName+'.webm';
                      link.click();
            })();
        },20000);

          //video.src = URL.createObjectURL(stream);
        }).catch(e => console.error(e));

}

const stopRecording = () => {

      // create local object URL from the recorded video blobs
    	let video_local = URL.createObjectURL(new Blob(blobs_recorded, { type: 'video/webm' }));
      //alert('recorded video_local---'+video_local);
    	//download_link.href = video_local;

      media_recorder.stop();

      newFile(blobs_recorded,'video_001.webm');

}

function newFile(blob, FileName) {
  var link = document.createElement("a"); // Or maybe get it from the current document
    link.href = blob;
    link.download = FileName;
    link.click();

}

  return (
    <div className='scan'>
            <img src={logo} alt="Docsun" className="logo"/>
            <p>Note : The scanning results are to be used only as a reference to identify the bias and learning curve in the system and should not be considered a diagnostic tool during this study.</p>
            <h2 id="checker">Modules Loading</h2>
            <video hidden id="video" width="640" height="480"> </video>
            <div className="scan__canvasContainer">
                <div className='progress__container'>
                    <div id="progress__value"></div>
                </div>
                <canvas id = "canvas" width="640" height="480"></canvas>
            </div>
            <div className="scan__vitalBox">
                {/* Basic Module elements */}
                <div className="scan__Box">
                    <label htmlFor='heart_rate'>Heart Rate</label><br/>
                    <input type="text" id="heart_rate"/>
                </div>
                <div className="scan__Box">
                    <label htmlFor='resp_rate'>Respiratory Rate</label><br/>
                    <input type="text" id="resp_rate"/>
                </div>
                <div className="scan__Box">
                    <label htmlFor='spo2'>Spo2 Level</label><br/>
                    <input type="text" id="spo2"/>
                </div>
            </div >
            <div className="scan__vitalBox">
                {/* Blood Pressure Module Elements */}
                <div className="scan__Box">
                    <label htmlFor='blood_pressure'>Blood Pressure</label><br/>
                    <input type="text" id="blood_pressure"/>
                </div>
                {/* Blood Sugar Module Elements */}
                <div className="scan__Box">
                    <label htmlFor='bs_rate'>Blood Sugar</label><br/>
                    <input type="text"  id="bs_rate"/>
                </div>
                {/* Blood Cholesterol Module Elements */}
                <div className="scan__Box">
                    <label htmlFor='chol_rate'>Blood Cholesterol</label><br/>
                    <input type="text"  id="chol_rate"/>
                </div>
                {/* Use to get Information about the modules */}
                <input hidden id='check_basic' readOnly/>
                <input hidden id='check_bp' readOnly/>
                <input hidden id='check_bs' readOnly/>
                <input hidden id='check_bc' readOnly/>
                <div className="scan__Box">
                <label htmlFor='diag_status' id='diag_label'>Health Status</label><br/>
                    <input id='diagnosis' type='text'/><input hidden type="text" id="diag_value"/>
                </div>
            </div >

            <div className='scan__BtnCont'>
                <button id="start_stop_btn" value="1" onClick={()=>startRecording()}>Start</button>
            </div>
            <p>Message : <span id='prog_dynamic'></span></p>
    </div>
  )

}

export default Scan
