import {React} from 'react'
import '../Questionare.css'
import { useLocation, useNavigate } from 'react-router-dom';

function Questionare() {

  const navigate = useNavigate();

  const { state } = useLocation();
  const { name } = state || {};
  const { age } = state || {};

  const openScan = () => {
      localStorage.setItem('DocsunScannerName',name);
      localStorage.setItem('DocsunScannerAge',age);
      location.href = "/scan";
      //navigate("/scan",{state :{ name : name, age:age }, replace:true});
  }

  return (
    <div>
      <iframe className="questionare-section" src="https://docs.google.com/forms/d/e/1FAIpQLSex2qEE6bAxeibVUw5tIdtxq5-z5gNz-95NDm1qsC0AGbpO_g/viewform?embedded=true" width="640" height="878">Loading…</iframe>
      <p>Please complete the Google form and then proceed to scanning.</p>
      <p><button className="btn-questionare" onClick={()=>openScan()}>Continue to Scanning</button></p>
    </div>
  )

}

export default Questionare
